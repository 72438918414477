import React from "react"

import PropTypes from "prop-types"

import TickIcon from "../../assets/images/tick.svg"
import Button from "../common/Button"
import Link from "../common/Link"
import "./CredentialChangeMessage.scss"

const CredentialChangeMessage = ({ email, changedCredential }) => {
  return (
    <div className="credential-change-message">
      <div className="credential-change-message__container">
        <img
          src={TickIcon}
          className="credential-change-message__icon"
          alt=""
        />
        {changedCredential === "Password" ? (
          <p className="credential-change-message__main-text">
            We have sent you an email to reset your passoword at {email}
          </p>
        ) : (
          <p className="credential-change-message__main-text">
            We have sent you an email to change your email address at {email}.
          </p>
        )}
        <p className="credential-change-message__main-text">
          Please check the email to{" "}
          {changedCredential === "Password"
            ? "reset your password."
            : "update your email address."}
        </p>
        <Button color="primary" isBlock>
          <Link to="/">Back Home</Link>
        </Button>
      </div>
    </div>
  )
}

CredentialChangeMessage.propTypes = {
  changedCredential: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

export default CredentialChangeMessage
